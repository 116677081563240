<template>
<v-app>
    <div>
        <v-container>
            <v-form>
                <v-row class="mx-auto my-auto">
                    <v-col cols=6 xl=3 lg=3 md=3 sm=4 class="pb-0">
                        <v-text-field :disabled='customDate' required label="Years back to search" v-model="years_back" v-on:keydown.enter.prevent='newQuery' v-on:keypress="isNumber($event)" clearable Regular color=warning></v-text-field>
                    </v-col>
                    <v-col cols=3 class="pb-0">
                        <v-alert :value="alert" type="error">Only numbers allowed!</v-alert>
                    </v-col>
                </v-row>

                <v-row class="mx-auto my-auto">
                    <v-col cols=4 class="pt-0">
                        <v-expansion-panels flat inset>
                            <v-expansion-panel>
                                <v-expansion-panel-header hide-actions @click="customdate =!customDate" class="ma-0 pa-0">
                                    <v-checkbox label="Use custom date range" v-model="customDate" color="info">
                                    </v-checkbox>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mb-2">EXAMPLE: </p>
                                    <p class="mb-2">Use the Boolean operator AND to limit your search to a date range.</p>
                                    <p style="color: blue;">pregnancy AND 2020/01/01:2021/12/31[dp]</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>

                    </v-col>
                </v-row>

                <v-row class='mx-auto mt-0'>
                    <v-col cols=12>
                        <v-textarea label="PubMed Query" background-color=secondary color=warning v-model="pubmed_query" v-on:keydown.enter.prevent='newQuery' clearable filled auto-grow></v-textarea>
                    </v-col>
                </v-row>
            </v-form>

            <v-container class="text-center mb-10">
                <v-btn :disabled="!(pubmed_query && years_back)" elevation="2" @click="newQuery" color=info>Submit</v-btn>
            </v-container>
            <template>
                <v-container class="pt-10">
                    <v-card>
                        <v-card-title class="success">
                            <h4>Query history</h4>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="search" color="warning" single-line hide-details class="p-table">
                            </v-text-field>
                        </v-card-title>
                        <v-data-table multi-sort :loading="loadingQueries" loading-text="Loading... Please wait" :search="search" :headers="headersQueries" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="queries" :items-per-page="10" :footer-props="{
                            'items-per-page-options': [5, 10, 20, 50]
                        }" color="info" class="elevation-1">
                            <template v-slot:item="{ item }">
                                <tr class="p-table">
                                    <td>
                                        <router-link :to="{ path: '/queryViewer', query: { qid: item.id }}">
                                            <p>
                                                {{truncate(item.query)}}
                                            </p>
                                        </router-link>
                                    </td>
                                    <td>{{item.timestamp | formatDateTime}}</td>
                                    <td>{{item.email}}</td>
                                    <td>{{item.pmids.length}}</td>
                                    <td>{{item.weeks | formatDateRange(item.customDate)}}</td>
                                    <td>{{item.was_too_long | withinLimit}}</td>
                                    <td>{{item.completed | makeYesNo}}</td>
                                    <td>
                                        <v-btn @click="delete_selected(item.id)" elevation="3" class="ml-3 mb-3 mt-0 mr-0" small color="info">
                                            delete
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-container>
            </template>
        </v-container>
    </div>
</v-app>
</template>

<script>
import {
    mapState,
    mapActions
} from "vuex";

export default {
    components: {},
    name: 'Queries',
    data() {
        return {
            pubmed_query: "",
            years_back: 3,
            sortBy: ["timestamp", "uid"],
            sortDesc: [true, false],
            search: '',
            alert: false,
            customDate: false,
            headersQueries: [{
                    text: "Query",
                    align: 'start',
                    value: "query",
                },
                {
                    text: "Date and time",
                    value: "timestamp",
                },
                {
                    text: "Submitted by",
                    value: "uid"
                },
                {
                    text: "Number of articles screened",
                    value: "pmid_length",
                },
                {
                    text: "Years back searched",
                    value: "weeks",
                },
                {
                    text: "Query within article limit?",
                    value: "was_too_long",
                    filterable: false,
                },
                {
                    text: "Query completed?",
                    value: "completed",
                    filterable: false,
                },
                {
                    text: "Delete query",
                    filterable: false,
                },
            ],
        }
    },
    computed: {
        ...mapState(["queries", "loadingQueries", "userProfile"]),
    },
    methods: {
        ...mapActions(["loadQueries", "submitQuery", "deleteSelectedQuery"]),
        newQuery() {
            this.submitQuery({
                query: this.pubmed_query,
                weeks: this.years_back * 52,
                customDate: this.customDate
            });
        },
        truncate(text, limit = 50, suffix = '...') {
            if (text.length > limit) {
                return text.substring(0, limit) + suffix;
            } else {
                return text;
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                this.alert = true;
            } else {
                return true;
            }
        },
        delete_selected(qid) {
            this.deleteSelectedQuery(qid);
        },
        hide_alert() {
            window.setInterval(() => {
                this.alert = false;
            }, 3000)
        }
    },
    mounted() {
        this.loadQueries();
        if (alert) {
            this.hide_alert();
        }
    },
}
</script>
